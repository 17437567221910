<template lang='pug'>
    div
        list(pageType='all')
</template>

<script>
import List from '../components/System/List.vue';

export default {
    components: {
        List,
    },
};
</script>
